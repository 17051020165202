<div>
  <app-page>
    <app-page-title title="Administration">
      <dx-button
        class="cta-btn"
        type="default"
        [text]="'ADMINISTRATION.OVERVIEW.DELETE' | translate"
        (click)="deleteSelected()"
      ></dx-button>
      <a [routerLink]="[getNewItemLink()]">
        <dx-button
          class="cta-btn"
          type="normal"
          icon="add"
          [text]="
            isTrainingGroupsTab
              ? ('ADMINISTRATION.OVERVIEW.ADD-GROUP' | translate)
              : ('ADMINISTRATION.OVERVIEW.ADD-CATEGORY' | translate)
          "
        ></dx-button>
      </a>
    </app-page-title>
    <dx-tabs
      width="100%"
      [selectedIndex]="(selectedTab$ | async) || 0"
      [showNavButtons]="false"
      [dataSource]="tabs"
      (onItemClick)="onTabChange($event)"
    ></dx-tabs>
    <div class="table-wrapper" *ngIf="isTrainingGroupsTab">
      <app-table
        [data]="(trainingGroups$ | async) || []"
        [columns]="trainingGroupColumns"
        [showSelection]="true"
        [showSearch]="true"
        [searchLabel]="'COMMON.SEARCH' | translate"
        [searchPlacehoder]="'COMMON.SEARCH' | translate"
        (selectHandler)="onRowSelected($event)"
        (onRowClick)="trainingGroupNavigateHandler($event)"
      ></app-table>
    </div>
    <div class="table-wrapper" *ngIf="!isTrainingGroupsTab">
      <app-table
        [data]="(trainingCategories$ | async) || []"
        [columns]="trainingCategoryColumns"
        [showSelection]="true"
        [showSearch]="true"
        [searchLabel]="'COMMON.SEARCH' | translate"
        [searchPlacehoder]="'COMMON.SEARCH' | translate"
        (selectHandler)="onRowSelected($event)"
        (onRowClick)="trainingCategoryNavigateHandler($event)"
      ></app-table>
    </div>
  </app-page>
</div>
