import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { VideoUploaderComponent } from 'src/app/components/shared/video-uploader/video-uploader.component';
import { CourseTemplateCreationAndUpdateBaseModel } from 'src/app/models/course/course-template-creation-and-update-base.model';
import { CourseTemplateFullModel } from 'src/app/models/course/course-template-full.model';
import { CourseTemplateQuestionOptionModel } from 'src/app/models/course/course-template-question-option.model';
import { CourseTemplateQuestionModel } from 'src/app/models/course/course-template-question.model';
import { OrganisationUnitTypeModel } from 'src/app/models/organisation-unit/organisation-unit-type.model';
import { PersonSummaryModel } from 'src/app/models/person/person-summary.model';
import { RawFileModel } from 'src/app/models/raw-file-model';
import { TableColumn } from 'src/app/models/table-column.model';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';
import { ContentFilePreviewModel } from 'src/app/models/content-file-preview.model';
import { CourseTemplateService } from 'src/app/services/course-template.service';
import { FileUtilService } from 'src/app/services/file-util.service';
import { LoadingService } from 'src/app/services/loading.service';
import { OrganisationUnitService } from 'src/app/services/organisation-unit.service';
import { TimeService } from 'src/app/services/time.service';
import { TrainingGroupService } from 'src/app/services/training-group.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { OrganisationUnitModel } from 'src/app/models/organisation-unit/organisation-unit.model';
import { TrainingGroupModel } from 'src/app/models/training-group.model';
import { CourseTemplateSummaryModel } from 'src/app/models/course/course-template-summary.model';
import { TrainingCategoryService } from 'src/app/services/training-category.service';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';
import { TranslateService } from '@ngx-translate/core';
import { LearningTypeModel } from 'src/app/models/training/learning-type.model';
import { LearningContentModel } from 'src/app/models/training/learning-content.model';
import { UserService } from 'src/app/services/user.service';
import { CourseContentTypeModel } from '../../../models/course/course-content-type.model';
import { CoursePublishingMode } from 'src/app/models/training/course-publishing-mode.model';
import { PersonEventFlagService } from 'src/app/services/person-event-flag.service';

type Question = CourseTemplateQuestionModel & {
  options: (CourseTemplateQuestionOptionModel & { index?: number })[];
  answerIndex: number;
};

@Component({
  selector: 'app-edit-manage-elearning',
  templateUrl: './edit-manage-elearning.component.html',
  styleUrl: './edit-manage-elearning.component.scss',
})
export class EditManageElearningComponent implements OnInit {
  private timePicker!: ElementRef<HTMLElement>;
  @ViewChild('timePicker') set content(content: ElementRef<HTMLElement>) {
    if (content) {
      this.timePicker = content;
    }
  }

  mode: 'edit' | 'view' | 'create' = 'create';
  generalFormData!: {
    name: string;
    description: string | null;
    contentFileId: string | null;
    isDeployed: boolean;
    invitationOnly: boolean;
    descriptionExtended: string | null;
    previewImage: RawFileModel | null;
  };
  trainingSettingsFormData!: {
    contentFileId: string;
    link: string;
    parentTemplateIds: string[];
    trainingType: TrainingTypeModel | null;
    publishingMode: CoursePublishingMode | null;
    iddHours: number;
    iddMinutes: number;
    sendCertificateLink: boolean;
    sendFeedbackLink: boolean;
    invitationOnly: boolean;
    trainingGroupIds: string[];
    trainingCategoryIds: string[];
    organisationUnitLocationIds: string[];
    sendInfomailToEmployees: boolean;
    sendInfomailToEmployeesReminderDays: number;
    lernart: LearningTypeModel | null;
    lerninhalt: LearningContentModel | null;
  };
  questionsFormData!: {
    questions: Question[];
  };
  isWbdConfigured: boolean = false;
  isDuplicate: boolean = false;
  duplicateCourseId!: string;
  duplicateVideoFile!: File;
  editingCourseId!: string;
  currentCourse: CourseTemplateFullModel | null = null;
  defaultSelectOptions = {
    stylingMode: 'outlined',
    displayExpr: 'name',
    valueExpr: 'id',
  } as DxSelectBoxTypes.Properties;
  locationsOptions!: DxSelectBoxTypes.Properties;
  parentTemplatesOptions!: DxSelectBoxTypes.Properties;
  trainingGroupsOptions!: DxSelectBoxTypes.Properties;
  trainingCategoriesOptions!: DxSelectBoxTypes.Properties;
  trainingTypesOptions!: DxSelectBoxTypes.Properties;
  learningTypeOptions!: DxSelectBoxTypes.Properties;

  publishingModeOptions!: DxSelectBoxTypes.Properties;

  learningContentOptions!: DxSelectBoxTypes.Properties;
  trainingTypeModelIDD = TrainingTypeModel.IDD;
  courseStatusOption: string[];
  contentFilePreview: ContentFilePreviewModel | null = null;
  thumbnailUrl$: Observable<SafeUrl | String | null> = of(null);
  personsColumns: TableColumn[] = [
    {
      caption: 'Last Name',
      dataField: 'lastName',
    },
    {
      caption: 'First Name',
      dataField: 'firstName',
    },
    {
      caption: 'Email',
      dataField: 'email',
    },
    {
      caption: 'Location',
      dataField: 'location.name',
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'close',
          class: 'red-icon',
          isShowAction: (): boolean => {
            return this.selectedTab$.getValue() === 'openPersons';
          },
          onClick: (person: PersonSummaryModel): void => {
            this.loadingService
              .load(
                this.personEventFlagService.deletePersonEventFlagCourseAssignment(
                  this.currentCourse!.courseTemplate.id,
                  person.id
                )
              )
              .subscribe(() => {
                const currentTab = this.selectedTab$.getValue();
                this.selectedTab$.next(currentTab);
              });
          },
        },
      ],
    },
  ];
  personsTabs: any[] = [
    {
      id: 0,
      text: 'Completed Employees',
      key: 'completedPersons',
    },
    {
      id: 1,
      text: 'Open Employees',
      key: 'openPersons',
    },
  ];
  displayPersons$!: Observable<PersonSummaryModel[]>;
  selectedTab$ = new BehaviorSubject<'completedPersons' | 'openPersons'>(
    this.personsTabs[0].key
  );
  organizationUnits!: OrganisationUnitModel[];

  isTrainingTimeValid: boolean = true;
  trainingTimeAdapter = {
    getValue: () => {
      return (
        this.trainingSettingsFormData.iddHours * 60 +
        this.trainingSettingsFormData.iddMinutes
      );
    },
  };

  @ViewChild('generalForm') generalForm!: DxFormComponent;
  @ViewChild('trainingSettingsForm') trainingSettingsForm!: DxFormComponent;
  @ViewChild('questionsForm') questionsForm!: DxFormComponent;
  @ViewChild('videoUploaderComponent')
  videoUploaderComponent!: VideoUploaderComponent;

  get isViewMode() {
    return this.mode === 'view';
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseTemplateService,
    private organisationUnitService: OrganisationUnitService,
    private trainingGroupService: TrainingGroupService,
    private trainingCategoryService: TrainingCategoryService,
    private fileUtilService: FileUtilService,
    private toastrService: ToastrService,
    private userService: UserService,
    readonly translateService: TranslateService,
    private personEventFlagService: PersonEventFlagService
  ) {
    this.courseStatusOption = [
      this.translateService.instant('COMMON.EVENT-STATUS.DRAFT'),
      this.translateService.instant('COMMON.EVENT-STATUS.DEPLOYED')
    ];
    this._setInitialFormData();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((param) => {
          const queryParamMode = param['mode'];
          const id = param['id'];

          if (id == 'new') {
            this.mode = 'create';
          } else if (!queryParamMode) {
            this.mode = 'view';
            this.editingCourseId = id;
          } else if (queryParamMode === 'edit' && id) {
            this.mode = 'edit';
          }

          console.log(history.state.duplicateId);
          if (history.state.duplicateId) {
            this.isDuplicate = true;
            this.duplicateCourseId = history.state.duplicateId;
          }

          return this.loadingService.load(
            this.isCreateMode
              ? this.isDuplicate
                ? this.courseService.getCourseTemplateClone(
                    this.duplicateCourseId
                  )
                : of(null)
              : this.courseService.getCourseTemplate(id)
          );
        })
      )
      .subscribe((result) => {
        this.currentCourse = result;

        if (!result) {
          this.loadPageData();
          return;
        }

        if (
          this.isDuplicate &&
          this.currentCourse!.contentFile &&
          this.currentCourse!.contentFile.url
        ) {
          this.loadingService
            .load(
              this.fileUtilService.imageUrlToBlob(
                this.currentCourse!.contentFile.url!
              )
            )
            .subscribe((blob) => {
              this.duplicateVideoFile = new File([blob!], 'video.mp4');
            });
        }

        this.displayPersons$ = this.selectedTab$.pipe(
          switchMap((tab) =>
            this.loadingService
              .load(
                this.courseService.getCourseTemplatePersons(
                  result.courseTemplate.id
                )
              )
              .pipe(map((persons) => persons[tab] as PersonSummaryModel[]))
          )
        );

        this.questionsFormData = {
          questions: result.questions.map((question) => ({
            ...question,
            answerIndex: question.options.findIndex((opt) => opt.isCorrect) + 1,
            options: question.options.map((opt, i) => ({
              ...opt,
              index: i + 1,
            })),
          })),
        };

        if (!this.questionsFormData.questions.length) {
          this.questionsFormData.questions = [this.createEmptyQuestion()];
        }

        this.videoUploaderComponent.setPreview(result);

        this.generalFormData = {
          name: result.courseTemplate.name,
          description: result.courseTemplate.description || null,
          contentFileId: result.contentFile?.fileId,
          descriptionExtended:
            result.courseTemplate.descriptionExtended || null,
          isDeployed: result.courseTemplate.isDeployed,
          invitationOnly: result.courseTemplate.invitationOnly,
          previewImage: null,
        };

        const iddHours = TimeService.getHoursFromAbsoluteMinutes(
          result.courseTemplate.iddTimeMinutes || 0
        );
        const iddMinutes = TimeService.getMinutesFromAbsoluteMinutes(
          result.courseTemplate.iddTimeMinutes || 0
        );
        this.trainingSettingsFormData = {
          parentTemplateIds: result.parentTemplateIds,
          trainingType: result.courseTemplate.trainingType,
          publishingMode: result.courseTemplate.publishingMode,
          iddHours,
          iddMinutes,
          sendCertificateLink: result.courseTemplate.sendCertificateLink,
          sendFeedbackLink: result.courseTemplate.sendFeedbackLink,
          invitationOnly: result.courseTemplate.invitationOnly,
          trainingGroupIds: result.trainingGroups.map((group) => group.id),
          trainingCategoryIds: result.trainingCategories.map(
            (category) => category.id
          ),
          organisationUnitLocationIds: result.organisationUnits.map(
            (unit) => unit.id
          ),
          link: result.courseTemplate.link,
          sendInfomailToEmployees:
            result.courseTemplate.sendInfomailToEmployees,
          sendInfomailToEmployeesReminderDays:
            result.courseTemplate.sendInfomailToEmployeesReminderDays,
          lernart: result.courseTemplate.lernart,
          lerninhalt: result.courseTemplate.lerninhalt,
          contentFileId: result.courseTemplate.contentFileId,
        };

        this.loadPageData();

        this.loadingService
          .load(this.videoUploaderComponent.getContentFilePreviewImage$())
          .subscribe((result) => {
            this.generalFormData.previewImage = result;
          });
      });
  }

  loadPageData(): void {
    const pageDataObject: any = {
      organizationUnits: this.organisationUnitService.getAllOrganisationUnits(),
      trainingGroups: this.trainingGroupService.getAllTrainingGroups(),
      trainingCategories:
        this.trainingCategoryService.getAllTrainingCategories(),
      isWbdConfigured: this.userService.getWbdConfigured(),
      parentTemplates: this.courseService
        .getAllCourseTemplates()
        .pipe(
          map((parentTemplates) =>
            parentTemplates.filter(
              (parentTemplate) =>
                this.currentCourse?.courseTemplate.id != parentTemplate.id
            )
          )
        ),
    };

    this.loadingService.load(forkJoin(pageDataObject)).subscribe((result) => {
      const {
        organizationUnits,
        trainingGroups,
        trainingCategories,
        parentTemplates,
        isWbdConfigured,
      } = result as {
        organizationUnits: Array<OrganisationUnitModel>;
        trainingGroups: Array<TrainingGroupModel>;
        trainingCategories: Array<TrainingCategoryModel>;
        parentTemplates: Array<CourseTemplateSummaryModel>;
        isWbdConfigured: boolean;
      };
      this.isWbdConfigured = isWbdConfigured;
      this.organizationUnits = [...organizationUnits];

      if (
        this.trainingSettingsFormData.organisationUnitLocationIds.length ==
          this.organizationUnits.length &&
        this.isViewMode
      ) {
        this.trainingSettingsFormData.organisationUnitLocationIds = ['0'];
        // @ts-ignore
        this.organizationUnits = [
          {
            id: '0',
            name: this.translateService.instant('COMMON.ALL'),
            parentId: null,
            type: 1,
            description:'',
            reportFooter: '',
            imageUrl: '',
            topLevel: true
          },
        ];
      } else {
        this.organizationUnits.forEach((organizationUnit) => {
          if (
            this.trainingSettingsFormData.organisationUnitLocationIds.indexOf(
              organizationUnit.id
            ) > -1
          ) {
            organizationUnit.selected = true;
          }
        });
      }

      const parentTemplatesDataSource = parentTemplates || [];
      parentTemplatesDataSource.forEach((template) => {
        if (
          this.trainingSettingsFormData.parentTemplateIds.indexOf(template.id) >
          -1
        ) {
          template.selected = true;
        }
      });

      const trainingGroupsDataSource = trainingGroups;
      trainingGroupsDataSource.forEach((trainingGroup) => {
        if (
          this.trainingSettingsFormData.trainingGroupIds.indexOf(
            trainingGroup.id
          ) > -1
        ) {
          trainingGroup.selected = true;
        }
      });

      const trainingCategoriesDataSource = trainingCategories.map(
        (category) => category.trainingCategory
      );
      trainingCategoriesDataSource.forEach((trainingCategory) => {
        if (
          this.trainingSettingsFormData.trainingCategoryIds.indexOf(
            trainingCategory.id
          ) > -1
        ) {
          trainingCategory.selected = true;
        }
      });

      this.locationsOptions = {
        ...this.defaultSelectOptions,
        placeholder: '',
        searchEnabled: true,
      };
      this.parentTemplatesOptions = {
        ...this.defaultSelectOptions,
        placeholder: '',
        dataSource: parentTemplatesDataSource,
        searchEnabled: true,
      };
      this.trainingGroupsOptions = {
        ...this.defaultSelectOptions,
        placeholder: '',
        dataSource: trainingGroupsDataSource,
        searchEnabled: true,
      };
      this.trainingCategoriesOptions = {
        ...this.defaultSelectOptions,
        placeholder: '',
        dataSource: trainingCategoriesDataSource,
        searchEnabled: true,
      };
      this.trainingTypesOptions = {
        ...this.defaultSelectOptions,
        showClearButton: true,
        dataSource: [
          {
            id: TrainingTypeModel.IDD,
            name: TrainingTypeModel[TrainingTypeModel.IDD],
          },
          {
            id: TrainingTypeModel.Compliance,
            name: TrainingTypeModel[TrainingTypeModel.Compliance],
          },
          {
            id: TrainingTypeModel.General,
            name: TrainingTypeModel[TrainingTypeModel.General],
          },
        ],
        placeholder: '',
        displayExpr: 'name',
        valueExpr: 'id',
      };
      this.publishingModeOptions = {
        ...this.defaultSelectOptions,
        showClearButton: true,
        dataSource: [
          {
            id: CoursePublishingMode.ByInvitationbOnly,
            name: this.translateService.instant(
              'COMMON.FIELDS.PUBLISHING-MODES.INVITATION-ONLY'
            ),
          },
          {
            id: CoursePublishingMode.DeployToTargetGroup,
            name: this.translateService.instant(
              'COMMON.FIELDS.PUBLISHING-MODES.DEPLOY-TARGET-GROUP'
            ),
          },
          {
            id: CoursePublishingMode.PublishToCatalogue,
            name: this.translateService.instant(
              'COMMON.FIELDS.PUBLISHING-MODES.PUBLISH-CATALOGUE'
            ),
          },
        ],
        placeholder: '',
        displayExpr: 'name',
        valueExpr: 'id',
      };
      this.learningTypeOptions = {
        ...this.defaultSelectOptions,
        showClearButton: true,
        dataSource: [
          {
            id: 1,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.FACE-TO-FACE'
            ),
          },
          {
            id: 2,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.INDIVIDUAL-TRAINING'
            ),
          },
          {
            id: 3,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.BLENDED-LEARNING'
            ),
          },
          {
            id: 4,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.CONTROLLED-ELEARNING'
            ),
          },
          {
            id: 5,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.SELF-DIRECTED-ELEARNING'
            ),
          },
          {
            id: 6,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARNING-TYPES.LEARNING-AT-WORK'
            ),
          },
        ],
        placeholder: '',
      };
      this.learningContentOptions = {
        ...this.defaultSelectOptions,
        showClearButton: true,
        dataSource: [
          {
            id: 1,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.PENSION-INSURANCE'
            ),
          },
          {
            id: 2,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.NURSING-INSURANCE'
            ),
          },
          {
            id: 3,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.DAMAGE-INSURANCE'
            ),
          },
          {
            id: 4,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.CUSTOMER-PENSION-PROVISION'
            ),
          },
          {
            id: 5,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.CUSTOMER-PROPERTY'
            ),
          },
          {
            id: 6,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.CROSS-DIVISIONAL'
            ),
          },
          {
            id: 7,
            name: this.translateService.instant(
              'COMMON.FIELDS.LEARING-CONTENT-TYPES.ADVISORY-COMPETENCE'
            ),
          },
        ],
        placeholder: '',
      };

      if (this.isCreateMode) {
        this.trainingSettingsFormData.sendCertificateLink = true;
        this.trainingSettingsFormData.sendFeedbackLink = true;
      }
    });
  }

  addQuestion(): void {
    this.questionsFormData.questions.push(this.createEmptyQuestion());
  }

  removeQuestion(): void {
    this.questionsFormData.questions.pop();
  }

  addQuestionOption(questionIndex: number): void {
    this.questionsFormData.questions[questionIndex].options.push({
      option: '',
      isCorrect: false,
      index: this.questionsFormData.questions[questionIndex].options.length + 1,
    });
  }

  removeQuestionOption(questionIndex: number): void {
    this.questionsFormData.questions[questionIndex].options.pop();
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.selected) {
      this.trainingSettingsFormData.trainingGroupIds = [
        ...this.trainingSettingsFormData.trainingGroupIds,
        e.node.key,
      ];
    } else {
      this.trainingSettingsFormData.trainingGroupIds =
        this.trainingSettingsFormData.trainingGroupIds.filter(
          (id) => id !== e.node.key
        );
    }
  }

  onTrainingCategorySelected(e: any) {
    if (e.node.selected) {
      this.trainingSettingsFormData.trainingCategoryIds = [
        ...this.trainingSettingsFormData.trainingCategoryIds,
        e.node.key,
      ];
    } else {
      this.trainingSettingsFormData.trainingCategoryIds =
        this.trainingSettingsFormData.trainingCategoryIds.filter(
          (id) => id !== e.node.key
        );
    }
  }

  onLocationsSelected(e: any) {
    this.trainingSettingsFormData.organisationUnitLocationIds = [];

    this.organizationUnits.forEach((unit) => {
      if (unit.selected) {
        this.trainingSettingsFormData.organisationUnitLocationIds.push(unit.id);
      }
    });
  }

  onParentTemplatesSelected(e: any) {
    if (e.node.selected) {
      this.trainingSettingsFormData.parentTemplateIds = [
        ...this.trainingSettingsFormData.parentTemplateIds,
        e.node.key,
      ];
    } else {
      this.trainingSettingsFormData.parentTemplateIds =
        this.trainingSettingsFormData.parentTemplateIds.filter(
          (id) => id !== e.node.key
        );
    }
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.trainingSettingsFormData.iddHours = value.hours;
    this.trainingSettingsFormData.iddMinutes = value.minutes;

    const trainingTimeGroupConfig =
      validationEngine.getGroupConfig('trainingTimeGroup');
    if (
      this.trainingSettingsFormData.trainingType === 0 &&
      trainingTimeGroupConfig
    ) {
      const isValid =
        validationEngine.validateGroup('trainingTimeGroup').isValid;

      if (isValid) {
        this.isTrainingTimeValid = true;
      }
    }
  }

  selectCorrectAnswer(questionIndex: number, answerIndex: number) {
    this.questionsFormData.questions[questionIndex].answerIndex = answerIndex;
    this.questionsFormData.questions[questionIndex].options =
      this.questionsFormData.questions[questionIndex].options.map(
        (option: any) => ({
          ...option,
          isCorrect: option.index === answerIndex,
        })
      );
  }

  contentFileChange(
    contentFile: {
      fileId: string;
      file: File;
      preview: RawFileModel;
    } | null
  ) {
    if (contentFile) {
      const { fileId, file, preview } = contentFile;
      this.generalFormData.contentFileId = fileId;
      this.generalFormData.previewImage = preview;
    } else {
      this.generalFormData.contentFileId = null;
      this.generalFormData.previewImage = null;
    }
  }

  submit() {
    let isValidationFailed = false;
    const isGeneralFormValid = this.generalForm.instance.validate();
    const isTrainingSettingsFormValid =
      this.trainingSettingsForm.instance.validate();
    const isQuestionsFormValid = this.questionsForm.instance.validate();
    const isTrainingTimeValid =
      this.trainingSettingsFormData.trainingType !== 0
        ? { isValid: true }
        : validationEngine.validateGroup('trainingTimeGroup');
    this.isTrainingTimeValid = isTrainingTimeValid.isValid!;

    if (!isGeneralFormValid.isValid && !isValidationFailed) {
      // @ts-ignore
      isGeneralFormValid.brokenRules[0].validator.focus();
      isValidationFailed = true;
    }

    if (!isTrainingSettingsFormValid.isValid && !isValidationFailed) {
      // @ts-ignore
      isTrainingSettingsFormValid.brokenRules[0].validator.focus();
      isValidationFailed = true;
    }

    if (!isTrainingTimeValid.isValid && !isValidationFailed) {
      this.timePicker.nativeElement.scrollIntoView();
      isValidationFailed = true;
    }

    if (
      !this.generalFormData.contentFileId &&
      (!this.currentCourse ||
        this.currentCourse.courseTemplate.courseContentType !=
          CourseContentTypeModel.External)
    ) {
      this.toastrService.error(
        this.translateService.instant('VALIDATIONS.VIDEO-REQUIRED')
      );
      return;
    }

    if (!this.trainingSettingsFormData.organisationUnitLocationIds.length) {
      this.organizationUnits.forEach((unit) => {
        this.trainingSettingsFormData.organisationUnitLocationIds.push(unit.id);
      });
    }

    if (
      !isGeneralFormValid.isValid ||
      !isQuestionsFormValid.isValid ||
      !isTrainingSettingsFormValid.isValid ||
      !isTrainingTimeValid.isValid
    ) {
      this.toastrService.error('Values are not valid');
      return;
    }

    const baseModel: CourseTemplateCreationAndUpdateBaseModel = {
      ...this.generalFormData,
      parentTemplateIds: this.trainingSettingsFormData.parentTemplateIds,
      organisationUnitLocationIds:
        this.trainingSettingsFormData.organisationUnitLocationIds,
      trainingType: this.trainingSettingsFormData.trainingType!,
      publishingMode: this.trainingSettingsFormData.publishingMode!,
      sendCertificateLink: this.trainingSettingsFormData.sendCertificateLink,
      sendFeedbackLink: this.trainingSettingsFormData.sendFeedbackLink,
      invitationOnly: this.trainingSettingsFormData.invitationOnly,
      trainingGroupIds: this.trainingSettingsFormData.trainingGroupIds,
      trainingCategoryIds: this.trainingSettingsFormData.trainingCategoryIds,
      iddTimeMinutes:
        this.trainingSettingsFormData.iddHours * 60 +
        this.trainingSettingsFormData.iddMinutes,
      questions: this.questionsFormData.questions
        .map((question) => ({
          question: question.question,
          options: question.options.map((option) => ({
            option: option.option,
            isCorrect: option.isCorrect,
          })),
        }))
        .filter((obj) => !!obj.question),
      lernart: this.trainingSettingsFormData.lernart,
      lerninhalt: this.trainingSettingsFormData.lerninhalt,
      sendInfomailToEmployees:
        this.trainingSettingsFormData.sendInfomailToEmployees,
      sendInfomailToEmployeesReminderDays:
        this.trainingSettingsFormData.sendInfomailToEmployeesReminderDays,
      link: this.trainingSettingsFormData.link || null,
    };

    if (baseModel.trainingType !== 0) {
      baseModel.lernart = null;
      baseModel.lerninhalt = null;
    }

    const method$ = this.isCreateMode
      ? this.courseService.addCourseTemplate(baseModel)
      : this.courseService.updateCourseTemplate(
          this.currentCourse!.courseTemplate.id,
          baseModel
        );

    this.loadingService.load(method$).subscribe({
      next: (course) => {
        this.toastrService.success('Course template saved successfully');
        this.loadingService.setIsFileUploading(false);

        if (this.isCreateMode) {
          this.navigateViewMode(course.courseTemplate.id);
        } else {
          this.navigateViewMode(this.currentCourse!.courseTemplate.id);
        }
      },
      error: () => {
        this.toastrService.error('Course template could not be saved');
        this.loadingService.setIsFileUploading(false);
      },
    });
  }

  onTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedTab$.next(e.itemData.key);
  }

  navigateBack(): void {
    this.router.navigate(['manage-elearning']);
  }

  navigateViewMode(id: string): void {
    this.router.navigate(['/manage-elearning/' + id]);
  }

  navigateToEdit(): void {
    this.router.navigate([
      'manage-elearning',
      'edit',
      this.currentCourse?.courseTemplate.id,
    ]);
  }

  private createEmptyQuestion(): Question {
    return {
      question: '',
      answerIndex: 1,
      options: new Array(3).fill(1).map((_, i) => ({
        option: '',
        isCorrect: i === 0,
        index: i + 1,
      })),
    };
  }

  private _setInitialFormData() {
    this.questionsFormData = {
      questions: [this.createEmptyQuestion()],
    };

    this.generalFormData = {
      name: '',
      description: null,
      contentFileId: null,
      descriptionExtended: null,
      isDeployed: false,
      invitationOnly: false,
      previewImage: null,
    };

    this.trainingSettingsFormData = {
      contentFileId: '',
      parentTemplateIds: [],
      trainingType: null,
      publishingMode: null,
      iddHours: 0,
      iddMinutes: 0,
      sendCertificateLink: false,
      sendFeedbackLink: false,
      invitationOnly: false,
      trainingGroupIds: [],
      trainingCategoryIds: [],
      organisationUnitLocationIds: [],
      // field that don't exist yet
      link: '',
      sendInfomailToEmployees: false,
      sendInfomailToEmployeesReminderDays: 0,
      lernart: null,
      lerninhalt: null,
    };
  }

  validateTrainingTime(e: any) {
    return e.value < 15 ? false : true;
  }

  protected readonly CourseContentTypeModel = CourseContentTypeModel;
}
