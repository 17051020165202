import { Component } from '@angular/core';
import { ExternalContactService } from '../../../services/external-contacts.service';
import { ContactSummaryModel } from '../../../models/contact/contact-summary.model';
import { TableColumn } from '../../../models/table-column.model';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-external-contacts-overview',
  templateUrl: './external-contacts-overview.component.html',
  styleUrls: ['./external-contacts-overview.component.scss'],
})
export class ExternalContactsOverviewComponent {
  contacts: ContactSummaryModel[] = [];
  contactsColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.LAST-NAME'),
      dataField: 'lastName',
      cssClass: 'width-200'
      
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.FIRST-NAME'),
      dataField: 'firstName',
      cssClass: 'width-200'
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.EMAIL'),
      dataField: 'email',
      cssClass: 'width-150'
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      cssClass: 'width-350',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];

          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: ContactSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') + ',' : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value ? (value + ',') : 'empty']];
      },
      cellValue: (data: ContactSummaryModel | any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
  ];

  constructor(
    private externalContactService: ExternalContactService,
    private router: Router,
    private loadingService: LoadingService,
    private translateService: TranslateService
  ) {
    this.loadExternalContacts();
  }

  ngOnInit(): void {}

  loadExternalContacts() {
    this.loadingService
      .load(this.externalContactService.getAllContacts())
      .subscribe((contacts) => (this.contacts = contacts));
  }
  
  navigateToDetails(id: string) {
    this.router.navigate(['/external-contacts/edit', id]);
  }
}
