<div>
  <app-page>
    <app-page-title title="{{ 'SIDENAV.MANAGE-ELEARNING' | translate }}">
      <a [routerLink]="['/manage-elearning/edit/new']" >
        <dx-button
          class="cta-btn"
          type="normal"
          icon="add"
          text="{{ 'MANAGE-ELEARNING.OVERVIEW.NEW-COURSE' | translate }}"
        ></dx-button>
      </a>
    </app-page-title>
    <app-table
      [data]="(allCourses$ | async) || []"
      [columns]="columns"
      [showSearch]="true"
      searchLabel="Search"
      searchPlacehoder="Search"
      (onRowClick)="navigateToCourse($event)"
    ></app-table>
  </app-page>
</div>
