<div>
  <app-page>
    <app-page-title
      title="{{
        personsFilterIds
          ? ('EMPLOYEE.OVERVIEW.TITLE' | translate) +
            ' (' +
            ('COMMON.FIELDS.FILTERED' | translate) +
            ')'
          : ('EMPLOYEE.OVERVIEW.TITLE' | translate)
      }}"
    >
      <dx-button
        *ngIf="selectedTab != 2"
        class="cta-btn"
        type="default"
        [disabled]="selectedPersons.length == 0"
        [text]="
          selectedTab == 0
            ? ('EMPLOYEE.FORM.SET-INACTIVE' | translate)
            : ('EMPLOYEE.FORM.SET-ACTIVE' | translate)
        "
        (click)="changeUsersStatus(selectedTab == 0 ? 'inactive' : 'active')"
      ></dx-button>
      <a [routerLink]="['/employee/add']">
        <dx-button
          class="cta-btn"
          type="normal"
          icon="add"
          text="{{ 'EMPLOYEE.OVERVIEW.ADD-EMPLOYEE' | translate }}"
        ></dx-button>
      </a>
    </app-page-title>
    <dx-tabs
      width="100%"
      [selectedIndex]="selectedTab"
      [showNavButtons]="false"
      [dataSource]="tabs"
      (onItemClick)="onTabChange($event)"
    ></dx-tabs>
    <div *ngIf="selectedTab != 2" class="employee-container">
      <app-table
        [data]="persons"
        [columns]="personsColumns"
        [showSelection]="true"
        [showSearch]="true"
        [showGrouping]="true"
        [searchLabel]="'COMMON.SEARCH' | translate"
        [searchPlacehoder]="'EMPLOYEE.OVERVIEW.FIND-EMPLOYEES' | translate"
        (onRowClick)="navigateToDetails($event)"
        (selectHandler)="onPersonsSelected($event)"
      ></app-table>
    </div>
    <div *ngIf="selectedTab == 2">
      <app-panel class="import-certificates">
        <div class="import-description">
          {{ "EMPLOYEE.OVERVIEW.IMPORT-COPY-1" | translate }}
          <a
            target="_blank"
            href="https://file.skeddle.com/skeddle/_import/skeddle%20Import%20Certificates.xlsx"
            >{{ "EMPLOYEE.OVERVIEW.IMPORT-EXCEL-FILE" | translate }}</a
          >
          {{ "EMPLOYEE.OVERVIEW.IMPORT-COPY-2" | translate }}
        </div>
        <div class="import-cta">
          <div class="file-picker">
            <dx-file-uploader
              #certificatesUploader
              [selectButtonText]="'COMMON.SELECT-FILE' | translate"
              labelText=""
              [multiple]="false"
              [allowedFileExtensions]="['.xls', '.xlsx']"
              (onValueChanged)="onCertificateSelected($event)"
              uploadMode="useForm"
              width="165px"
              [showFileList]="false"
            >
            </dx-file-uploader>
            <span class="file-name">{{ certificate && certificate.name }}</span>
          </div>
          <dx-button
            [disabled]="!certificate"
            type="normal"
            (click)="importCertificate()"
          >
            {{ "COMMON.IMPORT" | translate }}
          </dx-button>
        </div>
      </app-panel>
    </div>
  </app-page>
</div>
